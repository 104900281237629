import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import TransitionLink from 'gatsby-plugin-transition-link'
import React, { useRef } from 'react'
import Slick from "react-slick"
import "slick-carousel/slick/slick.css"
import Formularz from '../../components/formularz/formularz'
import Intro from '../../components/projekty/comp/projekt_intro'
import Inne from '../../components/projekty/inne'
import Identyfikacja from '../../components/projekty/lato/1'
import Print from '../../components/projekty/lato/2'
import Digital from '../../components/projekty/lato/3'
import Ocena from '../../components/projekty/OcenaProjektu'
import Seo from '../../components/seo.js'
import Slider from '../../components/slider/slider'
import back from '../../images/ikony/goback.svg'
import oko from '../../images/ikony/oko.svg'
import strzalka from '../../images/ikony/strzalka.svg'
import okladka2 from '../../images/projekty/lato/okladka2@2x.jpg'
import okladka from '../../images/projekty/lato/okladka@2x.jpg'
import style from './projekt.module.css'






let title = "LATO NA PODWÓRKU"

export default function Betard() {
    const wrapper = useRef(null)

    const r1 = useRef(null)
    const r2 = useRef(null)
    const r3 = useRef(null)
    const r4 = useRef(null)
    const r5 = useRef(null)
    const r6 = useRef(null)
    const r7 = useRef(null)
    const r8 = useRef(null)

    const slider = useRef(null)


    useScrollPosition(
        ({ prevPos, currPos }) => {
        console.log(currPos.x)
        console.log(currPos.y)
        },
        [],
        [wrapper],
        false,
        300,
        )

        let settingss = {
            dots: false,
            adaptiveHeight: true,
            infinite: false,
            arrows: false,
            centerMode: false,
            speed: 300,
            slidesToShow: 3,
            variableWidth: true, 
          }       



    return (    
        <>
        <div style={{
            height: "calc(100vh - 60px)",
            overflow: "y-scroll",
        }}
        ref={wrapper}>
            <div id="projekt-bread" className="projekt-aktywny projekt-header">
                <div className="lista-item">
                    <TransitionLink style={{
                        left: '0',
                        top: '0',
                        position:'absolute',
                    }} to="/projekty">
                        <img className="projekt-header-cofnij" src={back} />
                    </TransitionLink>
                    <h3>{title}</h3>
                    <div className="projekt-zajawka">
                        <img src={oko} />
                        <span className="przycisk">2020</span>
                        <div className="projekt-zajawka-kategorie">
                        <span className="przycisk">Identyfiacja wizualna</span>
                        <span className="przycisk">Print</span>
                        <span className="przycisk">Digital design</span>
                        </div>
                        
                        <p>„Lato na podwórku” to projekt wspierający rozwój kompetencji społecznych wśród dzieci. Naszym zadaniem było stworzenie identyfikacji wizualnej, kreacji reklamowych oraz materiałów dydaktycznych.</p>
                        <span className="przycisk">ZOBACZ PROJEKT</span>
                    </div>

                    { <div className="bread-dodatkowe slider-rozdzialy">
                    <div className="rozdzialy-maska"></div>
                    <Slick  ref={slider} className="rozdzial-slider" {...settingss}>
                    
                        <span onClick={
                            () => { r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(0);}}
                            className="przycisk">01. IDENTYFIKACJA WIZUALNA</span>
                        <span onClick={
                            () => { r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(1);}}
                        
                        
                        className="przycisk">02. PRINT</span>  
                        <span onClick={
                            () => { r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(2);}} 
                       className="przycisk">03. DIGITAL DESIGN</span>  
                       
                       
                      
            
                        
                    </Slick>
                    <div className="rozdzialy-maska"></div>
                    </div>}

                </div>

            </div>


            <Seo title={title} description="„Lato na podwórku” to projekt wspierający rozwój kompetencji społecznych wśród dzieci. Celem jest prowadzenie interwencji artystycznych, które inspirują współpracę i angażują w sposób twórczy, co przekłada się  na rozwój kreatywności i umiejętność nawiązywania relacji." image={okladka} />
            <div className="podstrona-wrapper projekt-padding">

            <img style={{width: '100%'}} src={okladka} />

            <Intro tytul="Wprowadzenie do projektu:" tekst="„Lato na podwórku” to projekt wspierający rozwój kompetencji społecznych wśród dzieci. Celem jest prowadzenie interwencji artystycznych, które inspirują współpracę i angażują w sposób twórczy, co przekłada się  na rozwój kreatywności i umiejętność nawiązywania relacji. Pomysł zrodził się  w Świdnicy, w ramach działań fundacji Ładne Historie a w 2021 roku odbyła się  już  jego 4. edycja. Naszym zadaniem było stworzenie identyfikacji wizualnej, kreacji reklamowych oraz materiałów dydaktycznych."/>

            <img style={{width: '100%'}} src={okladka2} />








            <div className={style.architektura}>
                <h3>ARCHITEKTURA PROJEKTU</h3>
                <div>

               
                        <div onClick={() => r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   } data-rozdzial={1}>
                            <span className="przycisk">
                                01. IDENTYFIKACJA WIZUALNA
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={2}>
                            <span className="przycisk">
                                02. PRINT
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={3}>
                            <span className="przycisk">
                                03. DIGITAL DESIGN
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>
                        
                 


           
                </div>
            </div>
            </div>

            <div ref={r1}><Identyfikacja  /></div>
            <div ref={r2}><Print /></div>
            <div ref={r3}><Digital  /></div>
    



            <div className="podstrona-wrapper">
            <Ocena tekst='"Odra to zespół złożony ze świetnych indywidualności, doświadczony w bardzo ciekawych i niełatwych projektach. Nasza współpraca przebiegała bardzo płynnie, na prawdziwie partnerskich warunkach. Bardzo doceniam sposób w jaki zespół Odry wychodził z inicjatywą, dbał o jakość naszej komunikacji i angażował się w podnoszenie standardów marki Mobile Vikings."' firma="Dyrektor Marketingu, Mobile Vikings" imie="Bartosz Grabowski" />

            <Slider  uslugi={true} />
            </div>

            <Inne omin="Lato na podwórku" />


            


            <Formularz />


            </div>
        </> 
    )
}