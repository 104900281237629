import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'
import Slider from '../comp/slider_foto'
import SliderTaby from '../comp/slider_taby'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import KolTekst from '../comp/kol_tekst'
import Img from "gatsby-image"
import Info from '../comp/punkt_flex'






export default function Strategia() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/lato/2/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                    }
                    }


                komp1: file(relativePath: { eq: "projekty/lato/2/komp1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/lato/2/komp2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp3: file(relativePath: { eq: "projekty/lato/2/komp3@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp4: file(relativePath: { eq: "projekty/lato/2/komp4@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp5: file(relativePath: { eq: "projekty/lato/2/komp5@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp6: file(relativePath: { eq: "projekty/lato/2/komp6@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp7: file(relativePath: { eq: "projekty/lato/2/komp7@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp8: file(relativePath: { eq: "projekty/lato/2/komp8@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp9: file(relativePath: { eq: "projekty/lato/2/komp9@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp10: file(relativePath: { eq: "projekty/lato/2/komp10@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp11: file(relativePath: { eq: "projekty/lato/2/komp11@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp12: file(relativePath: { eq: "projekty/lato/2/komp12@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp13: file(relativePath: { eq: "projekty/lato/2/komp13@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full1: file(relativePath: { eq: "projekty/lato/2/full1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full2: file(relativePath: { eq: "projekty/lato/2/full2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full3: file(relativePath: { eq: "projekty/lato/2/full3@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }


                

                

            }
            `)

    




    
    
    return(
        <>

        <Rozdzial tytul="PRINT" invert={true} tekst="Druk to jeden z podstawowych nośników komunikacyjnych projektu. Wykorzystywany jest m.in. w kampaniach promocyjnych, materiałach edukacyjnych i w ramach cyklicznych wystaw plakatów na przystankach miejskich w Świdnicy. Wszystkie materiały, dzięki zastosowaniu systemowych rozwiązań graficznych zachowują spójność i rozpoznawalność, co jest niezwykle istotne, biorąc pod uwagę, że wiele z nich funkcjonuje w chaotycznej i nadwyrężonej komunikacyjnie przestrzeni miejskiej." nr="02." img={data.okladka.childImageSharp.fluid}>

        <TytulPunkty tekst="W ramach projektu stworzyliśmy dziesiątki materiałów drukowanych – wśród nich plakaty, książeczki edukacyjne, kolorowanki." />  

        <KolTekst tekst="Plakaty to kluczowy środek wyrazu i komunikacji w ramach projektu – powodów jest wiele. To między innymi chęć utrzymania miejskiego i lokalnego charakteru projektu, potrzeba komunikacji z dzieciakami okupującymi lokalne podwórka, rodzicami przecierającymi codziennie te same lokalne ścieżki w drodze do pracy czy chęć bezpośredniego wpływu na jakość odbioru miasta. Z tego powodu plakat awansował z nośnika reklamowego do małego dzieła sztuki a jego różne odsłony prezentowane są w ramach cyklicznych wystaw na dedykowanych przez miasto Świdnicę wiatach przystankowych (to the bone!). Zobacz przygotowane przez nas kreacje!" tytul="PLAKATY" img={data.komp1.childImageSharp.fluid} />

        <Komp_kol invert={true} img1={data.komp2.childImageSharp.fluid} img2={data.komp3.childImageSharp.fluid} />

        <Komp_kol invert={true} img1={data.komp4.childImageSharp.fluid} img2={data.komp5.childImageSharp.fluid} />

        <Komp_kol invert={true} img1={data.komp6.childImageSharp.fluid} img2={data.komp7.childImageSharp.fluid} />

        <Komp_kol invert={true} img1={data.komp8.childImageSharp.fluid} img2={data.komp9.childImageSharp.fluid} />

        <Komp_kol invert={true} img1={data.komp10.childImageSharp.fluid} img2={data.komp11.childImageSharp.fluid} />

        <div>
            <Img fluid={data.full1.childImageSharp.fluid} />
        </div>

        <Info invert={true} tekst="Każdej edycji wydarzenia towarzyszą materiały edukacyjne dla dzieci. Treść zadań tworzona jest przez kolektyw DoDizajnu – my odpowiadamy za ich projekt graficzny. Z reguły są to karty z zadaniami nawiązującymi do tematu towarzyszącego kolejnym edycjom projektu. Drukowane w czerni i bieli pozwalają wybrzmieć  szaleństwom dzieci, które wyżywają  się  w nich kolorowymi kredkami. Poniżej przykładowe karty!" tytul="KSIĄŻECZKI EDUKACYJNE DLA DZIECI" />
        
        <Komp_kol invert={true} img1={data.komp12.childImageSharp.fluid} img2={data.komp13.childImageSharp.fluid} />

        <div>
            <Img fluid={data.full2.childImageSharp.fluid} />
        </div>

        <div>
            <Img fluid={data.full3.childImageSharp.fluid} />
        </div>
        

  


       




      
     



       
        
     

        

        
        
        </Rozdzial>
        
        </>
    )
}