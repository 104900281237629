import React from 'react'
import style from './komp_zdjecie.module.css'
import Img from "gatsby-image"

export default function komp_zdjecie(props) {

    if(props.invert) {
        return(
        <>
            <div className={style.wrapper_inverted}>
                <Img fluid={props.img} />
                <div className={style.tekst}>
                    <h6>{props.tytul}</h6>
                    <p>{props.tekst}</p>
                </div>
            </div>
        </>
        )
    }
    else {
        return(
            <>
                <div className={style.wrapper}>
                    <Img fluid={props.img} />
                    <div className={style.tekst}>
                        <h6>{props.tytul}</h6>
                        <p>{props.tekst}</p>
                    </div>
                </div>
            </>
        )
    }
   
}