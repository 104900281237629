import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'
import Slider from '../comp/slider_foto'
import Komp_zdjecie from '../comp/komp_zdjecie'
import Komp_kol from '../comp/kol_tekst'
import Komp_kol2 from '../comp/komp_2kol_tekst_foto'
import Img from "gatsby-image"
import wideo from '../../../images/projekty/betard/3/okladka.mp4'

import system from '../../../images/projekty/betard/3/system.mp4'


export default function Identyfikacja() {
    const data = useStaticQuery(graphql`
            query {
               
                okladka: file(relativePath: { eq: "projekty/lato/3/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

               

                komp1: file(relativePath: { eq: "projekty/lato/3/komp1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/lato/3/komp2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full1: file(relativePath: { eq: "projekty/lato/3/full1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

              

            }
            `)

    


  
    
    
    return(
        <>

        <Rozdzial tytul="DIGITAL DESIGN" invert={false} tekst="Materiały do mediów społecznościowych stanowią wyłącznie wspierający element działań. Ze względu na publiczny charakter przedsięwzięcia, jego edukacyjny wymiar i dążenie do obecności wśród rówieśników na podwórku, social media wykorzystują głównie przeformatowania podstawowych elementów kreacji. Nie mniej, modułowość systemu i jego ilustracyjny charakter pozwala w łatwy sposób dopasować się do obecności online, co widać na profilach społecznościowych projektu na Facebooku i Instagramie (sprawdź!)." nr="03." img={data.okladka.childImageSharp.fluid}>
        
        <TytulPunkty tekst="Modułowość  systemu i jego ilustracyjny charakter pozwala w łatwy sposób dopasować  się  do obecności online, co widać  na profilach społecznościowych projektu na Facebooku i Instagramie (sprawdź!)." />

        
        <Komp_kol2 invert={true} img1={data.komp1.childImageSharp.fluid} img2={data.komp2.childImageSharp.fluid} />

        <div>
            <Img fluid={data.full1.childImageSharp.fluid} />
        </div>
        

    


       
        
       

        

        
        
        </Rozdzial>
        
        </>
    )
}