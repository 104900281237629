import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'
import Slider from '../comp/slider_info'
import Komp_zdjecie from '../comp/komp_zdjecie'
import img from '../../../images/projekty/rite/1/okladka@2x.jpg'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import KolTekst from '../comp/kol_tekst'
import Img from "gatsby-image"

export default function Research() {
    const data = useStaticQuery(graphql`
            query {
             

                okladka: file(relativePath: { eq:  "projekty/lato/1/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },
                
                komp1: file(relativePath: { eq:  "projekty/lato/1/KOMP1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                        childImageSharp {
                            fluid(maxWidth: 1000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                },
                komp2: file(relativePath: { eq:  "projekty/lato/1/komp2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
            },   
            komp3: file(relativePath: { eq:  "projekty/lato/1/komp3@2x.jpg" },
            sourceInstanceName: {
                eq: "images"
              }) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
        },     
        komp4: file(relativePath: { eq:  "projekty/lato/1/komp4@2x.jpg" },
        sourceInstanceName: {
            eq: "images"
          }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
                }
            }
    },      
        full1: file(relativePath: { eq:  "projekty/lato/1/full1@2x.jpg" },
        sourceInstanceName: {
            eq: "images"
          }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
                }
            }
    },    


               

                
            }
            `)
    return(
        <>

        <Rozdzial tytul="IDENTYFIKACJA WIZUALNA" tekst="Podstawą do stworzenia koncepcji identyfikacji była inspiracja układem świdnickich ulic - tym jak się przecinają tworząc siatkę, a przestrzeń pomiędzy nimi staje się podwórkami. W oparciu o cztery filary działalności Lata Na Podwórku tj. profilaktyki, edukacji, tworzenia alternatyw i współdziałania, powstał system graficzny, który pozwala na tworzenie dowolnych materiałów, na wielu polach eksploatacji. Głównym środkiem wyrazu są tu ilustracje stworzone przez Kamila Lacha (Zgonowicz)." nr="01." img={data.okladka.childImageSharp.fluid}>

        <TytulPunkty tekst="Zabawa, dziecięcość, nadzieja i indywidualny charakter miasta - te wartości połączone z filarami edukacyjnymi programu stały się  inspiracją dla systemu graficznego."  />

        <Komp_kol img1={data.komp1.childImageSharp.fluid} img2={data.komp2.childImageSharp.fluid} invert={true} />

        <KolTekst invert={true} img={data.komp3.childImageSharp.fluid} tekst="Liternictwo zostało uzupełnione fotografią  i dynamicznymi symbolami, budującymi energię  projektu." />
        <div>
            <Img fluid={data.full1.childImageSharp.fluid} />
        </div>
        <KolTekst tytul="Brandbook" tekst="Nawet najmniejsze projekty wymagają merytorycznego opisu, dla zachowania spójności i zrozumienia założeń projektowych. Dla „Lata na podwórku” stworzyliśmy niewielki brandbook z elementami księgi znaku - to standardowa procedura dla wszystkich realizacji brandingowych tworzonych przez nasze studio." img={data.komp4.childImageSharp.fluid} />

        


       



        </Rozdzial>
        
        </>
    )
}